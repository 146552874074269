body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #6d5bba, #8d58bf, #de61b0, #f28a75);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite;
}

@keyframes gradientBackground {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.App {
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.container {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
}

.title {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.upload-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.upload-button {
  background-color: #f28a75;
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #de61b0;
}

.upload-button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.loading-spinner {
  margin-top: 20px;
  font-size: 1.2em;
  color: #ffeb3b;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.error-message {
  color: #ff4b4b;
  margin-top: 20px;
  font-size: 1em;
}

/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  background-color: #3e3e3e;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 900px;
  color: white;
  text-align: left;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.modal-body p {
  margin: 10px 0;
}

.report {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}


/* Add to your App.css */
.markdown-body h1, .markdown-body h2, .markdown-body h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.markdown-body p {
  margin-bottom: 15px;
}

.markdown-body ul {
  margin-left: 20px;
  margin-bottom: 15px;
}

.markdown-body li {
  margin-bottom: 5px;
}

.markdown-body blockquote {
  border-left: 4px solid #ddd;
  padding-left: 10px;
  color: #555;
  margin: 20px 0;
  font-style: italic;
}


.question-box {
  margin-top: 20px;
  padding: 20px;
  background-color: #4a4a4a;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.question-box h3 {
  font-size: 1.8em;
  color: #ffeb3b;
  margin-bottom: 10px;
}

.question-input {
  width: 60%;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #de61b0;
  background-color: #333;
  margin-bottom: 15px; 
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 15px;
  outline: none;
  transition: border-color 0.3s ease;
}

.question-input:focus {
  border-color: #f28a75;
}

.question-button {
  background-color: #f28a75;
  border: none;
  padding: 12px 20px;
  color: white;
  border-radius: 10px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin-top: 10px;
}

.question-button:hover {
  background-color: #de61b0;
}

.answer-box {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  color: #fff;
  font-size: 1.2em;
}


.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #2c3e50;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  color: white;
  overflow-y: auto;
}

.sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar ul li:hover {
  background-color: #34495e;
}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}


.delete-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c0392b;
}


.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
}

.login-container form {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.login-container .form-group {
  margin-bottom: 15px;
}

.login-container .form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.login-container .form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
}

.login-container .error-message {
  color: #ff4b4b;
  margin-bottom: 15px;
}

.login-container button {
  background-color: #f28a75;
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #de61b0;
}
